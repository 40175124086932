



















import { Component, Vue, Watch } from 'vue-property-decorator';

import Topology from 'topology-vue';
import { Store } from 'le5le-store';
import { Button, Icon } from 'ant-design-vue';
import axios from '@/http';

declare const topology: any;

Vue.use(Button);
Vue.use(Icon);

@Component({
  components: {
    Topology,
  },
})
export default class Preview extends Vue {
  data: any = { data: {} };
  locked = 0;
  showTools = true;

  @Watch('$route')
  routerChanged() {
    this.init();
  }

  subscribe: object = {};
  url = ''

  project: any = {
    db: '',
    projectCode: ''
  }

  created() {
    // iframe接收传值
    const _that= this
    _that.project.db = _that.$route.query.db
    _that.project.projectCode = _that.$route.query.projectCode

    window.addEventListener(
      'message',
      function(e: any) {
        let data = e.data;
        // console.log('aaaaaaaaaaaaa', data);
        
        if (data.type === 'webpackOk' || data.type === 'webpackInvalid') {
          return;
        } else {
          // _that.$http.post(`/link/preview/query`, { code: data.code, username: data.username }).then((res: any) => {
          //   // console.log('res', res.data.data[0]);
          //   _that.url = res.data.data[0].url + `&db=${res.data.data[0].db}&projectCode=${res.data.data[0].code}`
          //   window.parent.postMessage(_that.url, '*')
          // })
          window.parent.postMessage('适应屏幕', '*')
          _that.onSizeWindow()
        }
      },
      false
    );
    // if(!this.$route.query.id) {
    //   setTimeout(()=>{
    //     window.parent.postMessage(_that.url, '*')
    //   },4000)
    // }
    _that.init();
  }

  mounted() {
    topology.on('opened',() => {
      this.onSizeWindow()
      topology.options.cacheLen = 5
    })
    
    this.connectWebSocket()
  }

  connectWebSocket() {
    const json = sessionStorage.getItem('topologyData');
    if (!this.$route.query.id && json) {
      this.data = JSON.parse(json);
      setTimeout(() => {
        // 读到后清除对应 session
        sessionStorage.removeItem('topologyData');
      }, 200);
    }
    topology.data.socketEvent = true;
    topology.on('websocket', (e: any) => {
      // 接收和发送的消息都是 json 格式的，消息格式：{ topic: '--', info: {} }，具体见后端 websocket 文件
      const recData = JSON.parse(e);
      // console.log('recData', recData, 'collection', this.project.db, this.project.projectCode);
      
      switch (recData.topic) {
        case 'connection': {
          if (recData.info.connection) {
            const alarmSubscribe = {
              topic: 'systemDataSubscribe',
              info: { collection: `${this.project.db}${this.project.projectCode}` }
            };
            topology.socket.socket.send(JSON.stringify(alarmSubscribe));
            // console.log('websocket连接成功');
          }
          break;
        }
        case 'systemDataSubscribe': {
          // console.log('刷新数据：', JSON.parse(e).info.data.devices[0].deviceData);
          const res = JSON.parse(e).info.data.devices[0].deviceData;
          for (let item in res) {
            res[item] = res[item] === 0 ? '0' : res[item]
            topology.setValue(item, { text: res[item] });
          }
          // window.topology.setValue('SLP_0OVS019_S0', { text: res.SLP_0OVS019_S0 })
          break;
        }
        // case 'SLPAlarm': {
        //   console.log('接收到告警信息：', recData);
        //   break;
        // }
      }
    });
  }

  async init() {
    this.showTools = !!this.$route.query.r;

    if (this.$route.query.id) {
      let ret: any = await axios.get('/topologyapi/topology/' + this.$route.query.id, {
        params: {
          version: this.$route.query.version,
          view: 1,
        },
      });
      if (ret.error) {
        return;
      }

      if (!ret.pens) {
        const data = ret.data;
        delete ret.data;
        ret = Object.assign(ret, data);
      }
      this.data = ret;
    } else {
      this.data = (window as any).topologyData || {};
      setTimeout(() => {
        (window as any).topologyData = null;
      }, 200);
    }
  }

  onSizeWindow() {
    topology.fitView(16);
  }

  onSizeOri() {
    topology.open(this.data);
  }

  onBack() {
    topology.data.locked = this.locked;
    (window as any).topologyData = topology.data;
    this.$router.go(-1);
  }
}
